import { useState, useEffect } from 'react';
import { getAuthenticatedUser } from './common';
import { APP_ROUTES } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

export function useUser() {
    const [user, setUser] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function getUserDetails() {

            let { authenticated, user } = await getAuthenticatedUser() ;
            if (!authenticated) {
                navigate(APP_ROUTES.SIGN_IN);
                return;
            }
            setUser(await getAuthenticatedUser());
            setAuthenticated(authenticated);
        }
        getUserDetails();
    }, []);

    return { user, authenticated };
}