import {Link} from "react-router-dom";
import ChartCard from "../components/ChartCard";
import React from "react";

const Home = () => {

/*    const {user, authenticated} = useUser();
    if (authenticated && user) {
        navigate(APP_ROUTES.DASHBOARD)
    }*/
    return (

        <div className=" flex flex-col h-screen">
            {/* Hero Section */}
            <section className="bg-blue-500 text-white py-16">
                <div className="container mx-auto text-center">
                    <h1 className="text-4xl font-bold mb-4">Trade with Confidence</h1>
                    <p className="text-lg mb-8">Discover the world of Automated trading with our powerful platform.</p>
                    <Link to="/signup" className="px-6 py-3 bg-white text-blue-500 rounded-lg hover:bg-blue-200">Get
                        Started</Link>
                </div>
            </section>

            {/* Features Section */}
            <section className="bg-white-500 py-8 grow">
                <div className="container mx-auto">
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                        <div className="bg-blue-100 p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Advanced Charts</h2>
                            <p className="text-gray-700">Analyze market trends with our advanced charting tools.</p>
                        </div>
                        <div className="bg-blue-100 p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Real-time Data</h2>
                            <p className="text-gray-700">Access real-time market data for informed trading
                                decisions.</p>
                        </div>
                        <div className="bg-blue-100 p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Secure Transactions</h2>
                            <p className="text-gray-700">Trade securely with our encrypted payment system.</p>
                        </div>

                        <div className="bg-blue-100 p-6 rounded-lg">
                        <ChartCard
                            title="TheForce"
                        />
                        </div>
                        <div className="bg-blue-100 p-6 rounded-lg">
                            <ChartCard
                                title="TheForce"
                            />
                        </div>
                        <div className="bg-blue-100 p-6 rounded-lg">
                            <ChartCard
                                title="TheForce"
                            />
                        </div>


                    </div>

                </div>
            </section>

            {/* Footer */}
            <footer className="bg-gray-800 text-white py-8 sticky top-[100vh]">
                <div className="container mx-auto text-center">
                    <p>&copy; 2023 Bottles Enterprise. All rights reserved.</p>
                </div>
            </footer>

        </div>



        /*  <section>
              <div className="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
                  <div className="grid w-full grid-cols-1 gap-12 mx-auto lg:grid-cols-3">
                      <div className="p-6">
                          <div
                              className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-5 text-blue-600 rounded-full bg-blue-50">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   className="w-6 h-6 icon icon-tabler icon-tabler-aperture" width="24" height="24"
                                   viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                   strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <circle cx="12" cy="12" r="9"></circle>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(72 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(144 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(216 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(288 12 12)"></line>
                              </svg>
                          </div>
                          <h1 className="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">Short
                              length headline.</h1>
                          <p className="mx-auto text-base leading-relaxed text-gray-500">Free and Premium themes, UI
                              Kit's, templates and landing pages built with Tailwind CSS, HTML &amp; Next.js.</p>
                      </div>
                      <div className="p-6">
                          <div
                              className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-5 text-blue-600 rounded-full bg-blue-50">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   className="w-6 h-6 icon icon-tabler icon-tabler-aperture" width="24" height="24"
                                   viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                   strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <circle cx="12" cy="12" r="9"></circle>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(72 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(144 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(216 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(288 12 12)"></line>
                              </svg>
                          </div>
                          <h1 className="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">Short
                              length headline.</h1>
                          <p className="mx-auto text-base leading-relaxed text-gray-500">Free and Premium themes, UI
                              Kit's, templates and landing pages built with Tailwind CSS, HTML &amp; Next.js.</p>
                      </div>
                      <div className="p-6">
                          <div
                              className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-5 text-blue-600 rounded-full bg-blue-50">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   className="w-6 h-6 icon icon-tabler icon-tabler-aperture" width="24" height="24"
                                   viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                   strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <circle cx="12" cy="12" r="9"></circle>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(72 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(144 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(216 12 12)"></line>
                                  <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(288 12 12)"></line>
                              </svg>
                          </div>
                          <h1 className="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">Short
                              length headline.</h1>
                          <p className="mx-auto text-base leading-relaxed text-gray-500">Free and Premium themes, UI
                              Kit's, templates and landing pages built with Tailwind CSS, HTML &amp; Next.js.</p>
                      </div>
                  </div>
              </div>
          </section>*/


    )
};

export default Home;
