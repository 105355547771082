import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";

import Dashboard from "./pages/Dashboard";
import {APP_ROUTES} from "./utils/constants";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import Contact from "./pages/Contact";
import SubmitStrategy from "./pages/SubmitStrategy";
import Services from "./pages/Services";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

<BrowserRouter>
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />

                <Route path="home" element={<Home />} />
                <Route path="services" element={<Services />} />
                <Route path="submit_strategy" element={<SubmitStrategy />} />

                <Route path="contact" element={<Contact />} />




            <Route path="dashboard" element={<Dashboard />} />

            <Route path={APP_ROUTES.SIGN_UP} element={<SignUp />} />
            <Route path={APP_ROUTES.SIGN_IN} element={<SignIn />} />

            <Route path="*" element={<p>Path not resolved</p>} />
        </Route>
    </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
