import AWS from "aws-sdk";
import React from "react";

const SubmitStrategy = () => {
    //Bucket Configurations


    var s3 = new AWS.S3({
        accessKeyId: 'be-public-strategy-upload-user',
        secretAccessKey: 'z8raVbR6pH8Ih5n5QImwiT1H5aMJA9',
        endpoint: 'http://s3.apps.hhc.express:9000/',
        s3ForcePathStyle: 'true', // needed with minio?
        signatureVersion: 'v4'
    });

    const uploadToS3 = async (e) => {
        var strategy_file = document.getElementById('strategy_file').files;
        var config_file = document.getElementById('config_file').files;

        var strategy_name = document.getElementById('strategy_name').value;

        function upload(filePath, file) {
            s3.upload({
                Key: filePath,
                Body: file,
                Bucket: "be-public-strategy-upload",
                Metadata: {"client-ip": "12.12.12.12"}
            }, function (err, data) {
                if (err)
                    console.log(err)
                else
                    console.log("Successfully uploaded data to " + filePath);
            });
        }

            if (strategy_file && config_file) {
                {
                    var file = strategy_file[0]
                    var fileName = file.name;
                    var filePath =  strategy_name + '/' + fileName;
                    upload(filePath, file)

                    file = config_file[0]
                    fileName = file.name;
                    filePath =  strategy_name + '/' + fileName;
                    upload(filePath, config_file[0])

                }
            }
        }
    ;
    return (
        /*        <div className="flex items-center justify-center p-12">

                    <div className="mx-auto w-full max-w-[550px] bg-white">


                        <form

                        >
                            <div className="mb-5">
                                <label
                                    className="mb-3 block text-base font-medium text-[#07074D]"
                                >
                                    Enter Name of Strategy
                                </label>
                                <input
                                    type="text"
                                    name="strategy_name"
                                    id="strategy_name"
                                    placeholder="MACD_on_Steroids_v100_binance_5m"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                />
                            </div>

                            <div className="mb-6 pt-4">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                                    Upload File
                                </label>

                                <div className="mb-8">
                                    <input type="file" name="file" id="file" className="sr-only"/>
                                    <label
                                        htmlFor="file"
                                        className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                    >
                                        <div>
                              <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                                Drop files here
                              </span>
                                            <span className="mb-2 block text-base font-medium text-[#6B7280]">
                                Or
                              </span>
                                            <span
                                                className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]"
                                            >
                                Browse
                              </span>
                                        </div>
                                    </label>
                                </div>

                                <div className="mb-5 rounded-md bg-[#F5F7FB] py-4 px-8">
                                    <div className="flex items-center justify-between">

                                        <span className="truncate pr-3 text-base font-medium text-[#07074D]">
                              config.json
                            </span>
                                        <button className="text-[#07074D]">
                                            <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="rounded-md bg-[#F5F7FB] py-4 px-8">
                                    <div className="flex items-center justify-between">

                            <span className="truncate pr-3 text-base font-medium text-[#07074D]">
                              Strategy.py
                            </span>
                                        <button className="text-[#07074D]">
                                            <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="relative mt-5 h-[6px] w-full rounded-lg bg-[#E2E5EF]">
                                        <div
                                            className="absolute left-0 right-0 h-full w-[75%] rounded-lg bg-[#6A64F1]"
                                        ></div>

                                    </div>
                                </div>
                            </div>

                            <div>
                                <button
                                    className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                                    onClick={uploadToS3}
                                >
                                    Send File
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

        */


        /*
                        <div>
                            <div>
                                <input type="file" id="fileUpload"/>
                            </div>

                            <div>
                                <button onClick={uploadToS3}>Upload</button>
                            </div>
                        </div>*/

        <div className="flex items-center justify-center p-12">

            <div className="mx-auto w-full max-w-[550px] bg-white">


                <div>
                    <div className="mb-5">
                        <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                            Enter Name of Strategy
                        </label>


                        <input
                            type="text"
                            name="strategy_name"
                            id="strategy_name"
                            placeholder="MACD_on_Steroids_v100_binance_5m"
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                    </div>

                    <div className="mb-6 pt-4">
                        <label
                            className="mb-3 block text-base font-medium text-[#07074D]">
                            Select Strategy

                        </label>
                        <input type="file" id="strategy_file"/>

                        {/*Only for Format*/}
                        <label
                            className="mb-3 block text-base font-medium text-[#07074D]">
                        </label>

                        <label
                            className="mb-3 block text-base font-medium text-[#07074D]">
                            Select Config

                        </label>
                        <input type="file" id="config_file"/>



                    </div>

                    <div>
                        <button
                            className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                            onClick={uploadToS3}>
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>


    );

}


export default SubmitStrategy;
