const API_URL = 'https://bapi.c0re.at'
//const API_URL = 'http://127.0.0.1:1337'
export const API_ROUTES = {
    SIGN_UP: `${API_URL}/register`,
    SIGN_IN: `${API_URL}/login`,
    GET_USER: `${API_URL}/user/me`,
}

export const APP_ROUTES = {
    SIGN_UP: '/signup',
    SIGN_IN: '/signin',
    DASHBOARD: '/dashboard',
    HOME : '/home'

}