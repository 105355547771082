import React from 'react';
import {useUser} from '../lib/CustomHooks';
import ChartCard from "../components/ChartCard";


const Dashboard = () => {
    const { user, authenticated } = useUser();
    if (user && authenticated) {
        return(

            <>


                <div className="container my-12  px-4 md:px-6 flex items-center ml-auto">
                    <div className="flex flex-wrap -mx-1 lg:-mx-4 ">

                        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                            <ChartCard
                                title="TheForce"
                            />
                        </div>

                        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                            <ChartCard
                                title="ichiv2"
                            />
                        </div>

                        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                            <ChartCard
                                title="NotAnotherSMA"
                            />
                        </div>
                        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                            <ChartCard
                                title="Brutal Strategy"
                            />
                        </div>

                    </div>
                </div>

            </>




        );
    }
    return (
        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

            <ChartCard
                title="Brutal Strategy"
            />
        </div>
    )

}

export default Dashboard;





/*<div className="p-16 bg-gray-800 h-screen">
                <div className="text-2xl mb-4 font-bold text-white"> Dashboard </div>
                {
                    user &&
                    <div className='text-white'>
                        <div className="text-lg text-bold mb-2"> User Details </div>
                        <div className="flex">
                            <div className="w-24 font-medium">
                                <div> Email : </div>
                                <div> Firstname : </div>
                                <div> Lastname : </div>
                            </div>
                            <div>
                                <div> {user.username} </div>
                                <div> {user.firstName} </div>
                                <div> {user.lastName} </div>
                            </div>
                        </div>
                    </div>
                }
            </div>*/