import React from 'react';
import axios from 'axios';
import {useState} from 'react';
import {API_ROUTES, APP_ROUTES} from '../utils/constants';
import {Link, useNavigate} from 'react-router-dom';
import {useUser} from '../lib/CustomHooks';
import {storeTokenInLocalStorage} from '../lib/common';

const SignIn = () => {
    const navigate = useNavigate();
    const {user, authenticated} = useUser();
    if (authenticated && user) {
        window.location.reload(false);
        navigate(APP_ROUTES.DASHBOARD)

    }

    const [isLoading, setIsLoading] = useState(false);


    const signIn = async (event) => {
        const formData = new FormData(event.currentTarget);
        event.preventDefault();

        try {

            setIsLoading(true);
            const response = await axios({
                method: 'post',
                mode: 'no-cors',
                url: API_ROUTES.SIGN_IN,
                data: {
                    username: formData.get("username"),
                    password: formData.get("password")
                }
            });


            if (!response?.data?.access_token) {
                console.log('Something went wrong during signing in: ', response);
                return;
            }
            console.log('Token: ', response.data.access_token);
            storeTokenInLocalStorage(response.data.access_token);
            navigate(APP_ROUTES.DASHBOARD)
        } catch (err) {
            console.log('Some error occured during signing in after: ', err);
        } finally {
            setIsLoading(false);
            //navigate(APP_ROUTES.DASHBOARD)
        }
    };


    return (<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-10 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt="Bottled Enterprise"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Login
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={signIn} className="space-y-6">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                autoComplete="username"
                                placeholder="Enter Your Username"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="text-sm">
                                <Link to="password_reset"
                                      className="font-semibold text-indigo-600 hover:text-indigo-500">
                                    Forgot password?
                                </Link>
                            </div>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                placeholder="*******"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />


                        </div>
                    </div>

                    <div>
                        <button
                      /*      onClick={signIn}*/
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sign in
                        </button>
                        {
                            isLoading ?
                                <div className="mr-2 w-5 h-5 border-l-2 rounded-full animate-spin"/> : null
                        }
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?{' '}
                    <Link to="../signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                        Register
                    </Link>
                </p>
            </div>
        </div>


    );
}

export default SignIn;