import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const ChartCard = ({ title, data }) => {
    const data_frames = [{
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    }, {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    }, {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    }, {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    }, {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    }, {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    }, {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },];

    return (
        <div>
            <h4 className="text-xl font-bold mb-4 text-blue-600">
                {title}
            </h4>


            <div className="object-cover w-full h-48">

                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data_frames}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{r: 8}}/>
                        <Line type="monotone" dataKey="uv" stroke="#82ca9d"/>
                    </LineChart>
                </ResponsiveContainer>

            </div>
            <div className="p-4">

                <div>
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">Select a tab</label>
                        <select id="tabs" name="tabs"
                                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                            <option defaultValue>My Account</option>
                            <option>Company</option>
                            <option>Team Members</option>
                            <option>Billing</option>
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav className="isolate flex divide-x divide-gray-100 rounded-lg shadow"
                             aria-label="Tabs">
                            <a href="#"
                               className="text-gray-900 rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                               aria-current="page">
                                <span>Hour</span>
                                <span aria-hidden="true"
                                      className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"></span>
                            </a>
                            <a href="#"
                               className="text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10">
                                <span>Day</span>
                                <span aria-hidden="true"
                                      className="bg-transparent absolute inset-x-0 bottom-0 h-0.5"></span>
                            </a>
                            <a href="#"
                               className="text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10">
                                <span>Week</span>
                                <span aria-hidden="true"
                                      className="bg-transparent absolute inset-x-0 bottom-0 h-0.5"></span>
                            </a>
                            <a href="#"
                               className="text-gray-500 hover:text-gray-700 rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10">
                                <span>Month</span>
                                <span aria-hidden="true"
                                      className="bg-transparent absolute inset-x-0 bottom-0 h-0.5"></span>
                            </a>
                        </nav>
                    </div>
                </div>

            </div>
        </div>


    );



    /*



    use card
        <div className="flex justify-center items-center h-screen">
            <TradingCard
                title="Trading Card Example"
                description="This is an example of a trading card component."
                imageUrl="https://example.com/image.jpg"
            />
        </div>
*/
};

export default ChartCard;